@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.section {
  composes: landingSection from global;
  min-height: 300vh;
  height: fit-content;

  @include respond(desktop) {
    padding: 100px 50px;
  }

  @include respond(tablet) {
    padding: 75px 40px;
  }

  @include respond(mobile, large-mobile) {
    padding: 25px 20px;
  }
}

.slide {
  height: fit-content;
  position: relative;
}

.contentWrapper {
  position: absolute;
  top: 18%;
  left: 10%;
  flex-direction: column;
  width: 100%;

  @include respond(desktop) {
    position: relative;
    top: 0;
    left: 0;
  }
}

.topShape {
  position: absolute;
  top: -250px;

  svg {
    opacity: 0;
  }

  @include respond(tablet) {
    scale: 0.8;
    transform-origin: center;
  }

  @include respond(mobile, large-mobile) {
    top: -100px;
    scale: 0.55;
    transform-origin: top;
    transform: translateX(-60%);
  }
}

.bottomShape {
  position: absolute;
  bottom: -16%;
  left: -130px;
  scale: 0.8;

  @include respond(desktop) {
    left: -200px;
    scale: 0.7;
    top: 25px;
    transform-origin: top left;
  }

  @include respond(large-mobile) {
    left: -250px;
    scale: 0.6;
  }

  @include respond(mobile) {
    left: -160px;
    scale: 0.4;
  }
}

.maskedImage {
  position: absolute;
  bottom: -21%;
  left: 65px;
  scale: 0.8;

  @include respond(desktop) {
    scale: 0.6;
    transform-origin: top left;
    top: 20px;
    z-index: 2;
    left: 0;
  }

  @include respond(large-mobile) {
    scale: 0.5;
    transform: translateX(-125px);
  }

  @include respond(large-mobile) {
    scale: 0.5;
    transform: translateX(-125px);
  }

  @include respond(mobile) {
    scale: 0.35;
    transform: translateX(-150px);
  }
}

.videoWrapper {
  position: absolute;
  right: -25%;
  bottom: -2%;
  height: 75%;

  @include respond(xxl-desktop) {
    height: 91%;
  }

  @include respond(desktop) {
    @include absoluteCenter;
    transform: translate(-180px, 50px);
    height: 290px;
    scale: 2;
    transform-origin: top left;
  }

  @include respond(tablet) {
    scale: 1.7;
    transform: translate(-150px, 50px);
  }

  @include respond(large-mobile) {
    scale: 1.5;
  }

  @include respond(mobile) {
    scale: 1;
  }
}

.centerShape {
  position: absolute;
  left: 25%;
  top: 5%;
  z-index: 2;
  scale: 0.7;

  @include respond(desktop) {
    left: 125px;
    top: -90px;
    scale: 0.35;
  }
}

.video {
  height: 100%;
  mask: url('./svgs/video-mask.svg');
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: 50% 50%;

  &.--2 {
    scale: 1.6;

    @include respond(desktop) {
      transform: translatex(110px) translateY(0px) rotate(-1deg);
    }
  }
}

.videoShape {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;

  @include respond(desktop) {
    transform: translatex(130px) translateY(0px) rotate(-1deg);
  }
}

.tag {
  transform: translateY(-150%);
}

.textWrapper {
  text-align: left;
}

.titleLine {
  @include tyH1;
  font-size: 11.6rem;
  position: relative;
  height: 90px;
  display: flex;
  align-items: center;
  line-height: 140px;
  transition: all 1s;

  @include respond(desktop) {
    line-height: 90%;
    height: auto;
    font-size: 5.4rem;
    display: initial;
    margin-right: 1%;
  }

  @include respond(large-mobile) {
    display: block;
  }
}

.titlePrimary {
  position: relative;
}

.titleSecondary {
  position: relative;
  font-family: $font-milo-for-manor-regular;
}

.titleAppendix {
  @include tyCtas;

  &.before {
    position: absolute;
    top: -60%;
    left: -1.5%;

    @include respond(desktop) {
      visibility: hidden !important;
    }
  }

  &.after {
    transform: translateY(30px) translateX(5px);

    @include respond(desktop) {
      transform: none;
      margin-left: 1rem;
    }
  }
}

.description {
  @include tySmallParagraph;
  position: relative;
  margin-top: 3%;
  max-width: 30%;
  margin-left: 12%;

  @include respond(desktop) {
    max-width: 100%;
    margin-left: 0;
    margin-top: 0;
  }

  @include respond(large-mobile) {
    margin-top: 2%;
  }
}

.descriptionDivider {
  margin: 2% 0 4%;

  @include respond(desktop) {
    overflow: hidden;
    margin: -1% 0 1%;
  }
}

.descriptionTitle {
  text-transform: uppercase;
}

.descriptionPager {
  font-family: $font-milo-for-manor-bold;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.015em;
  margin-top: 15%;

  @include respond(desktop) {
    position: absolute;
    top: 450px;
    margin-top: 0;
  }

  @include respond(large-mobile) {
    position: absolute;
    top: 350px;
    margin-top: 0;
  }

  @include respond(mobile) {
    top: 250px;
  }
}

.arrow {
  position: absolute;
  top: -1%;
  left: -5%;

  @include respond(desktop) {
    visibility: hidden !important;
  }
}

.mediaWrapper {
  position: relative;
  padding-top: 25px;
}

:export {
  colorGreen: $color-green;
}
